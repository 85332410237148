import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, {Component} from 'react'
import Layout from "../components/layout"
import { Jumbotron, Button, Container } from 'reactstrap'
import { navigate, Link } from '@reach/router';
import qs from 'query-string';
import { handleVerify } from '../services/auth';

export default class verify extends Component {
    constructor(props) {
        super(props);

        const queryData = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        this.state = {
            username : queryData.username,
            code : queryData.code,
            status : false,
            isValid : true
        }

        
    }

    componentDidMount(){
        if(this.state.username === undefined || this.state.code === undefined){
            window.location.replace('/')
        } else {
            this.submitVerification()
        }
    }

    submitVerification = async () => {
        await handleVerify(this.state)
        .then((data) => {

            if(data.isValid){
                this.setState({
                    status : true,
                    isValid : true
                })
            } else {
                this.setState({
                    status : true,
                    isValid : false
                })
            }

        })
        .catch(err => console.log(err))
    }

    render(){
        return (
            <Layout>
                <Container
                    style={{
                        padding: '2rem 2rem 1rem',
                        minHeight: '75vh'
                    }}>
                {/* Set message while loading result */}
                {((!this.state.status && this.state.isValid) ? (
                    <Jumbotron >
                        <h2 className="display-4">
                            Loading...
                        </h2>
                        <p className="lead">
                            We're trying to validate your email. Please wait.
                        </p>
                    </Jumbotron>
                ) : ((this.state.status && !this.state.isValid) ? (
                    // Set message if invalid code
                    <Jumbotron >
                        <h2 className="display-4">
                            Activation error!
                        </h2>
                        <p className="lead">
                            Something went wrong while trying to activate your account. The link you have provided is either expired or already validated.<br/>
                            Click <Link to="/forgot/">here</Link> to request for another activation code.
                        </p>
                    </Jumbotron>
                ): (
                    // Set message if valid code
                    <Jumbotron >
                        <h2 className="display-4">
                            Congratulations!
                        </h2>
                        <p className="lead">
                            Your account has been activated. You can now login to your account to access our APIs and apply for API keys<br />
                        </p>
                        <p className="lead">
                            <Button
                                color="primary"
                                onClick={event => {
                                    event.preventDefault()
                                    navigate('/signin/')
                                }}
                            >
                                Login now
                            </Button>
                        </p>

                    </Jumbotron>
                )))}

                </Container>
            </Layout>
        )
    }
}